import React from "react";
import styled from "react-emotion";
import { Timeline as AntTimeline } from "antd";

import { colors } from "./dito";

import "antd/dist/antd.css";

const Wraper = styled(AntTimeline)`
  color: ${colors.text};
  text-align: ${({ mode }) => mode !== "alternate" && "left"};
`;

const Item = styled(AntTimeline.Item)`
  font-size: 18px;
  .ant-timeline-item-head {
    border-color: ${colors.text};
    background-color: ${colors.background};
  }
  span {
    color: ${({ selected, past }) =>
      selected ? colors.highlight : past ? "#556873" : colors.highlight};
  }

  color: ${({ selected, past }) =>
    selected ? colors.highlight : past ? "#556873" : colors.text};

  font-weight: ${({ selected }) => (selected ? 600 : 400)};
`;

const SideWraper = styled(Wraper)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const SideItem = styled(Item)`
  span {
    font-size: ${({ selected }) => (selected ? "18px" : "16px")};
    font-weight: ${({ selected }) => (selected ? "600" : "300")};
    color: ${({ selected }) => (selected ? colors.highlight : colors.text)};
  }
`;

const items = [
  {
    text:
      "Bailão entra na Dito. Guardávamos usuários eventos no MySQL e tínhamos problema para gerar relatórios.",
    date: "Out/15"
  },
  {
    text: "Começamos a usar Google BigQuery para gerar relatórios.",
    date: "Dez/15"
  },
  {
    text:
      "Problemas para escalar nosso motor de segmentação, baseado em Elasticsearch.",
    date: "Fev/16"
  },
  {
    text:
      "Stream de dados em Kinesis, subscribers em containers no Kubernetes e uma nova versão do Elasticsearch com mapping melhorado.",
    date: "Nov/16"
  },
  {
    text: "Load de dados no BigQuery a partir do MySQL não escalava mais.",
    date: "Jan/17"
  },
  {
    text:
      "Fizemos nosso próprio ETL que carrega os dados no BigQuery a partir do Kinesis.",
    date: "Jun/17"
  },
  {
    text: "Alto custo com consultas no BigQuery",
    date: "Ago/18"
  },
  {
    text: "Dias atuais.",
    date: "Abr/19"
  }
];

export const CompleteTimeline = ({ index }) => (
  <Wraper mode="alternate">
    {items.map(({ text, date }, i) => (
      <Item selected={index === i} past={i < index}>
        <span>{date}</span> {text}
      </Item>
    ))}
  </Wraper>
);

export const Timeline = ({ index }) => (
  <SideWraper mode="right">
    {items.map(({ date }, i) => (
      <SideItem selected={index === i}>
        <span>{date}</span>
      </SideItem>
    ))}
  </SideWraper>
);
