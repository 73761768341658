import React from "react";

import { Slide as SpectacleSlide } from "spectacle";

export const Slide = ({ children, ...props }) => (
  <SpectacleSlide {...props}>
    {children}
    <Logo
      style={{
        position: "absolute",
        right: "16px",
        bottom: "16px"
      }}
    />
  </SpectacleSlide>
);

export const Logo = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="77"
    height="35"
    viewBox="0 0 68 31"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFFFFF"
        d="M17.96 19.323c-.024-3.255-2.918-6.083-6.204-6.064-3.3.018-6.218 2.899-6.177 6.098.043 3.37 2.95 6.21 6.288 6.145 3.313-.065 6.117-2.908 6.093-6.18zm4.64-.469c-.027 5.466-3.146 9.504-8.286 10.824-5.777 1.484-11.95-2.328-13.096-8.086-1.216-6.1 2.786-11.758 8.866-12.68 2.65-.404 5.012.018 7.353 1.942V1H22.6v17.854zM25 30h4.8V9.458H25zM27.4 5.833c1.326 0 2.4-1.082 2.4-2.416A2.409 2.409 0 0 0 27.4 1 2.408 2.408 0 0 0 25 3.417a2.408 2.408 0 0 0 2.4 2.416"
      />
      <path
        fill="#39C881"
        d="M47.44 29.758c1.325 0 2.4-1.082 2.4-2.416a2.409 2.409 0 0 0-2.4-2.417 2.409 2.409 0 0 0-2.4 2.417 2.408 2.408 0 0 0 2.4 2.416"
      />
      <path
        fill="#FFFFFF"
        d="M56.2 25.274c-3.373 0-6.107-2.752-6.107-6.149 0-3.397 2.734-6.15 6.107-6.15s6.107 2.753 6.107 6.15c0 3.397-2.734 6.149-6.107 6.149m0-17.024c-5.965 0-10.8 4.87-10.8 10.875 0 1.498.301 2.926.845 4.225a4.078 4.078 0 0 1 1.222-.185c2.264 0 4.1 1.848 4.1 4.129 0 .534-.103 1.043-.287 1.511A10.684 10.684 0 0 0 56.2 30C62.165 30 67 25.131 67 19.125S62.165 8.25 56.2 8.25"
      />
      <path
        fill="#FFFFFF"
        d="M43.199 27.364c0-1.001.349-1.915.926-2.624-3.192-.36-4.134-2.449-4.134-5.269V14.05h4.296A12.748 12.748 0 0 1 47.8 9.356h-7.809V1h-5.067v8.356H32.2v4.693h2.724v5.422c0 4.764 2.011 10.075 9.212 10.529a4.144 4.144 0 0 1-.937-2.636"
      />
    </g>
  </svg>
);
