// Import React
import React from "react";

// Import Spectacle Core tags
import {
  Appear,
  Code,
  Deck,
  Heading,
  ListItem,
  List,
  Text,
  Image
} from "spectacle";

import { createTheme, Slide, colors } from "./dito";
import { CompleteTimeline, Timeline } from "./timeline";

// Require CSS
require("normalize.css");

const images = {
  whatIsDito: require("./assets/what-is-dito.png"),
  mysqlArch: require("./assets/mysql-arch.png"),
  addingBQ: require("./assets/adding-bq.png"),
  newArch: require("./assets/new-arch.png"),
  bqETLLoad: require("./assets/bq-etl-load.png"),
  wtf: "https://media.giphy.com/media/11ahZZugJHrdLO/giphy.gif",
  goodJob: "https://media.giphy.com/media/RIhNQOjGa39Ze/giphy.gif",
  money: "https://media.giphy.com/media/VTxmwaCEwSlZm/giphy.gif"
};

export default class Presentation extends React.Component {
  state = { value: 0, previous: 0 };
  render() {
    return (
      <Deck theme={createTheme()} showFullscreenControl={false} progress="none">
        <Slide>
          <Heading size={2} textAlign="left" margin="0 0 ">
            Evoluções na Arquitetura de Dados da Dito
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <hr
            style={{
              width: "185px",
              height: "8px",
              backgroundColor: colors.highlight,
              margin: "16px 0",
              border: "none"
            }}
          />
          <Text textAlign="left">Arthur Bailão</Text>
        </Slide>

        <Slide>
          <Heading size={1}>
            O que é Dito
            <span style={{ color: colors.highlight }}>?</span>
          </Heading>
        </Slide>

        <Slide>
          <Image src={images.whatIsDito} />
        </Slide>

        <Slide>
          <CompleteTimeline />
        </Slide>

        <Slide>
          <CompleteTimeline index={0} />
        </Slide>

        <Slide>
          <Timeline index={0} />
          <Heading size={3} textAlign="left">
            Arquitetura
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <Image src={images.mysqlArch} width={800} />
        </Slide>

        <Slide>
          <Timeline index={0} />
          <Heading size={3} textAlign="left">
            Problema
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <List style={{ maxWidth: 800 }}>
            <ListItem>
              Tabelas <Code>users</Code> e <Code>events</Code> cresciam
              indefinidamente
            </ListItem>
            <ListItem>
              Relatórios de receita consolidada por mês demoravam uma infinidade
              para rodar
            </ListItem>
            <ListItem>
              Processar dados de 1 dia demorava mais de 24h{" "}
              <span role="img" aria-label="wtf">
                🤯
              </span>
            </ListItem>
          </List>
        </Slide>

        <Slide>
          <CompleteTimeline index={1} />
        </Slide>

        <Slide>
          <Timeline index={1} />
          <Image src={images.addingBQ} width={800} />
        </Slide>

        <Slide>
          <Timeline index={1} />
          <Heading size={3} textAlign="left">
            Pouco tempo depois
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <List style={{ maxWidth: 800 }}>
            <ListItem>
              Fizemos um script para extrair <Code>events</Code> do MySQL
            </ListItem>
            <ListItem>Subimos os dados no Google BigQuery</ListItem>
            <ListItem>
              Os relatório de 1 dia rodaram em alguns segundos
            </ListItem>
          </List>
          <Appear>
            <Heading size={4} style={{ color: colors.highlight }}>
              Colocamos isso pra rodar em uma Crontab!
            </Heading>
          </Appear>
        </Slide>

        <Slide>
          <Image src={images.wtf} width={800} />
        </Slide>

        <Slide>
          <Timeline index={1} />
          <Heading size={3} textAlign="left">
            Resultados
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <Appear>
            <List style={{ maxWidth: 800 }}>
              <ListItem>
                Foi uma solução extremamente simples que entregou valor muito
                rápido
              </ListItem>
              <ListItem>Gastamos pouco tempo de desenvolvimento</ListItem>
              <ListItem>
                Nos deu um poder imenso como solução de Data Warehouse
              </ListItem>
              <ListItem>[Spoiler] Funcionou bem por mais 1 ano</ListItem>
            </List>
          </Appear>
        </Slide>

        <Slide>
          <CompleteTimeline index={2} />
        </Slide>

        <Slide>
          <Timeline index={2} />
          <Heading size={3} textAlign="left">
            Problema
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <List style={{ maxWidth: 900 }}>
            <ListItem>
              Serviço de segmentação baseado em Elasticsearch não escalava
              indexação
            </ListItem>
            <ListItem>
              Mapping utilizava uma estrutura de documentos nested,
              carinhosamente batizados de{" "}
              <span style={{ color: colors.highlight }}>fat-users</span>
            </ListItem>
            <ListItem>
              Todos os índices foram criados com configurações default, 5
              shards, 1 índice por cliente
            </ListItem>
            <ListItem>
              Nosso cluster não parava de pé{" "}
              <span role="img" aria-label="dead">
                😵
              </span>
            </ListItem>
          </List>
        </Slide>

        <Slide>
          <CompleteTimeline index={3} />
        </Slide>

        <Slide>
          <Timeline index={3} />
          <Image src={images.newArch} width={900} />
        </Slide>

        <Slide>
          <Timeline index={3} />
          <Heading size={3} textAlign="left">
            Muito tempo depois
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <List style={{ maxWidth: 800 }}>
            <ListItem>
              Criamos nosso stream de dados utilizando AWS Kinesis
            </ListItem>
            <ListItem>
              Começamos a utilizar Docker e colocamos o Kinesis Subscriber em
              produção no Kubernetes
            </ListItem>
            <ListItem>Atualizamos o Elasticsearch para a versão 2</ListItem>
            <ListItem>
              Mudamos o mapping de documentos para parent/child para otimizar a
              indexação
            </ListItem>
          </List>
        </Slide>

        <Slide>
          <Image src={images.goodJob} width={500} />
        </Slide>

        <Slide>
          <Timeline index={3} />
          <Heading size={3} textAlign="left">
            Resultados
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <List style={{ maxWidth: 800 }}>
            <ListItem>Resolvemos o problema de indexação</ListItem>
            <ListItem>
              O stream de dados nos deu muito poder para criação de novos
              serviços
            </ListItem>
          </List>
        </Slide>

        <Slide>
          <Timeline index={3} />
          <Heading size={3} textAlign="left">
            Mas
            <span style={{ color: colors.highlight }}>...</span>
          </Heading>
          <Appear>
            <List style={{ maxWidth: 800 }}>
              <ListItem>
                Era uma entrega muito grande que envolveu várias tecnologias
                novas
              </ListItem>
              <ListItem>
                Processo longo de validação pois todas as queries mudaram
              </ListItem>
              <ListItem bold>
                Demoramos 9 meses para gerar qualquer tipo de valor
              </ListItem>
              <ListItem>
                Hoje vemos quer seria totalmente possível ter resolvido apenas o
                problema com o Elasticsearch primeiro
              </ListItem>
            </List>
          </Appear>
        </Slide>

        <Slide>
          <CompleteTimeline index={4} />
        </Slide>

        <Slide>
          <Timeline index={4} />
          <Heading size={3} textAlign="left">
            Problema
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <List style={{ maxWidth: 800 }}>
            <ListItem>
              Aquele script da Crontab começou a falhar dado o crescimento no
              volume de dados
            </ListItem>
            <ListItem>
              Escrever os dados uma vez por dia não atendia mais
            </ListItem>
            <ListItem>O MySQL crescia desenfreadamente</ListItem>
          </List>
        </Slide>

        <Slide>
          <CompleteTimeline index={5} />
        </Slide>

        <Slide>
          <Timeline index={5} />
          <Image src={images.bqETLLoad} width={900} />
        </Slide>

        <Slide>
          <Timeline index={5} />
          <Heading size={3} textAlign="left">
            Escrevemos nosso próprio ETL
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <List style={{ maxWidth: 800 }} ordered>
            <ListItem>Baixa os dados do S3</ListItem>
            <ListItem>Transforma para o schema do BigQuery</ListItem>
            <ListItem>
              Guarda um backup no Gloud Storage e faz o load no BigQuery
            </ListItem>
          </List>
        </Slide>

        <Slide>
          <Timeline index={5} />
          <Heading size={3} textAlign="left">
            Resultados
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <List style={{ maxWidth: 800 }}>
            <ListItem>O BigQuery ficou near real-time</ListItem>
            <ListItem>
              Temos "warm backup" de todos os dados do BigQuery
            </ListItem>
            <ListItem>Isso funciona bem até hoje!</ListItem>
          </List>
        </Slide>

        <Slide>
          <CompleteTimeline index={6} />
        </Slide>

        <Slide>
          <Timeline index={6} />
          <Heading size={3} textAlign="left">
            Problema
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <List style={{ maxWidth: 800 }}>
            <ListItem>
              Os custos com BigQuery vem aumentando desde Ago/18
            </ListItem>
            <ListItem>Vários produtos e relatórios dependem do BQ</ListItem>
            <ListItem>
              Com a criação da equipe de Data Science esse custo tende a
              aumentar
            </ListItem>
          </List>
        </Slide>

        <Slide>
          <Heading size={2}>
            Dias atuais
            <span style={{ color: colors.highlight }}>...</span>
          </Heading>
        </Slide>

        <Slide>
          <Image src={images.money} width={900} />
        </Slide>

        <Slide>
          <Timeline index={7} />
          <Heading size={3} textAlign="left">
            O que temos em mente
            <span style={{ color: colors.highlight }}>.</span>
          </Heading>
          <List style={{ maxWidth: 800 }}>
            <ListItem>
              Reindexar nossos dados em time-partitioned tables
            </ListItem>
            <ListItem>Todas as queries vão mudar</ListItem>
          </List>
        </Slide>

        <Slide>
          <Heading size={2}>
            Valeu
            <span style={{ color: colors.highlight }}>!</span>
          </Heading>
          <Heading size={1}>
            Perguntas
            <span style={{ color: colors.highlight }}>?</span>
          </Heading>
        </Slide>
      </Deck>
    );
  }
}
